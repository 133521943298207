import * as $ from 'jquery';

const App = () => {
  const confirmModal = document.getElementById('closeModal');
  $('#closeQuestion').on('click', () => {
    confirmModal.classList.remove('hidden');
  });
  $('#cancelClose').on('click', () => {
    confirmModal.classList.add('hidden');
  });
}
// NOTE: 画面遷移した時用
document.addEventListener("turbolinks:load", () => {
  App();
});

// NOTE: リフレッシュした時用
$(document).ready(() => {
  App();
});
