import * as $ from 'jquery';

const App = () => {
  $('#dropDownBtn').on('click', () => {
    $('#myDropdown').toggleClass('hidden');
  });
}
// NOTE: 画面遷移した時用
document.addEventListener("turbolinks:load", () => {
  App();
});

// NOTE: リフレッシュした時用
$(document).ready(() => {
  App();
});
